// @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background: #f3f7fd;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Mukta', sans-serif;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  outline: none;
}

input {
  outline: none;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.no-pointer-event {
  pointer-events: none;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
  margin: .5rem 0;
}

.inline-block {
  display: inline-block;
}

.full-height {
  height: 100%;
}

.position-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.header-main {
  font-size: 38px;
  // font-size: 2.375rem;
  font-weight: 400;
}

.header-normal {
  font-size: 32px;
  // font-size: 2rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.header-18 {
  font-size: 18px;
  // font-size: 1.125rem;
  margin-bottom: 10px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 700;
}

.body-container {
  padding: 40px !important;
}

.visibility-visible {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
}

.tag {
  > span {
    margin-right: 5px;
    cursor: pointer;
  }
  i {
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
  }
  .sub-tag {
    display: inline-block;
    border: 1px solid #3333;
    border-radius: 4px;
    margin-left: 8px;
    padding: 3px;
    margin-bottom: 4px;
    background: #fff;
  }
}
input::placeholder {
  color: var(--tint-shade-n10);
}

.input {
  &-text {
    color: var(--primary-n40);
    border: 1px solid var(--primary-b20);
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
    background: var(--white);
    padding: 12px;

    ~ .button {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      padding: 10px 20px;
      background: var(--tint-shade-b20);
      margin: 3px;
      color: var(--white);
      border-radius: 4px;
      // display: none;
      font-weight: 700;
      text-transform: capitalize;
    }

    // &:focus{
    // 	~ .button {
    // 		display: block;
    // 	}
    // }
  }

  &-icon {
    padding: 12px 20px 12px 40px;
    position: relative;

    + i {
      position: absolute;
      top: 8px;
      left: 10px;
    }
  }
}

.field-search {
  position: relative;

  // &:hover{
  // 	.input-text{
  // 		~ .button {
  // 			display: block;
  // 		}
  // 	}
  // }
}

// .form-search{
// 	&:hover{
// 		.ui.input.action{
// 			input{
// 				~ .button {
// 					display: block;
// 				}
// 			}
// 		}
// 	}
// }

.icon-done{
  color: var(--tint-shade-g20);
  border: 1px solid var(--tint-shade-g20);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .material-icons{
    font-weight: bold;
  }
}