.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    flex-direction: column;

    form{
        padding: 50px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 5px rgba(0,0,0,0.1);

        .login-button{
            text-align: center;

            button{
                box-shadow: 0 0px 0px rgba(0,0,0,0.1);
                &:hover{
                    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
                }
            }
        }
    }
}
