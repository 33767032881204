#map {
  height: 765px;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.marker-cluster-small {
  background-color: rgba(109, 168, 83, 0.6);
}
.marker-cluster-small div {
  background-color: rgb(109, 168, 83);
}

.marker-cluster-medium {
  background-color: rgba(243, 146, 55, 0.6);
}
.marker-cluster-medium div {
  background-color: rgb(243, 146, 55);
}

.marker-cluster-large {
  background-color: rgba(37, 100, 222, 0.6);
}
.marker-cluster-large div {
  background-color: rgb(37, 100, 222);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
  font-weight: 700;
  color: var(--white);
}

.leaflet-popup-content {
  font-weight: 700;
  color: var(--primary-n40);
}
.leaflet-container a.leaflet-popup-close-button {
  color: var(--primary-n40);
  top: 1px;
  right: 1px;
}

.leaflet-tooltip.feature-label {
  background: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 0px;
  box-shadow: 0 0px 0px;

  &.hide {
    display: none;
  }
}
