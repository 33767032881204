.samapurak-chart-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.samapurak-chart-card {
  position: relative;
  width: 49%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0 45px 40px -40px rgba(37, 100, 222, 0.26);
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  min-height: 400px;
}

.samapurak-chart-card-full {
  position: relative;
  width: 98%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0 45px 40px -40px rgba(37, 100, 222, 0.26);
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  min-height: 400px;
}

.stat-cards-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .stat-card {
    width: calc(14.28571% - 20px);
    word-break: break-word;
    padding: 20px;
    height: 120px;
    margin-right: 20px;
    border-radius: 6px;
    background: var(--tint-shade-b10);

    &__number {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.stat-map-wrapper {
  display: flex;
  border: 1px solid rgba(114, 115, 142, 0.1);

  &__left {
    width: 25%;
    padding: 24px;
    background: #ffffff;
    position: relative;
  }

  &__right {
    min-height: 765px;
    width: 75%;
    position: relative;

    .leaflet-overlay-pane {
      z-index: 0;
    }

    .leaflet-pane {
      z-index: 0;
    }

    .leaflet-top,
    .leaflet-bottom {
      z-index: 0;
    }
  }
}

.stat-list {
  margin: 0;
  padding: 0;

  &__item {
    padding: 16px 0 8px;
    word-break: break-word;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(114, 115, 142, 0.1);
    }
  }
}

.stat-name {
  font-size: 14px;
  line-height: 23px;
  color: #72738e;
  margin-bottom: 6px;
}

.stat-number {
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #333456;
}

.samapurak-project-search {
  margin: 30px 0;
}

.samapurak-project-location {
  font-weight: 400;
  margin-top: 5px;
}
