:root{
    --white: #fff;
    --black: #000;
    --primary-b0: #f5f8ff;
    --primary-b20: #2564de;
    --primary-n40: #2a2f38;
    --primary-n40--70: rgba(42, 47, 56, 0.7);

    --secondary-r20: #d63230;
    --secondary-o20: #f39237;  
    --secondary-g20: #6da853;

    --tint-shade-b40: #001554;
    --tint-shade-b30: #0635A5;
    --tint-shade-b20: #2564DE;
    --tint-shade-b10: #7ca7f8;
    --tint-shade-b5: #b8cffc;
    --tint-shade-b5--20: rgba(184, 207, 252, 0.2);
    
    --tint-shade-n40: #2A2F38;
    --tint-shade-n30: #475060;
    --tint-shade-n20: #697588;
    --tint-shade-n10: #9aa2af;
    --tint-shade-n5: #cacfd7;
    
    --tint-shade-r40: #870b00;
    --tint-shade-r30: #b7140c;
    --tint-shade-r20: #d63230;
    --tint-shade-r10: #ee8282;
    --tint-shade-r5: #f7bcbb;
    --tint-shade-r0: #fff8f8;
    
    --tint-shade-o40: #870b00;
    --tint-shade-o30: #b7140c;
    --tint-shade-o20: #d63230;
    --tint-shade-o10: #F9BB81;
    --tint-shade-o5: #f7bcbb;
    --tint-shade-o0: #fff8f8;
    
    --tint-shade-g40: #3b642a;
    --tint-shade-g30: #4e8237;
    --tint-shade-g20: #6da853;
    --tint-shade-g10: #88c26d;
    --tint-shade-g5: #b4e49e;
    --tint-shade-g0: #f6fff1;
    
    --tint-shade-p40:#3B0587;
    --tint-shade-p30:#5822B5;
    --tint-shade-p10:#B49BEC;

    --tint-shade-t30:#5ACBD2;
    --tint-shade-t10:#9BE8EC;
    --tint-shade-t5:#C5F3F5;

    --draft: var(--tint-shade-r10);
    --submitted: var(--tint-shade-g10);
    --verification: var(--tint-shade-o10);
    --approved: var(--tint-shade-t30);
}